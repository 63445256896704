body {
    background-color: #f8f9fa; /* Общий цвет фона */
    font-family: 'Roboto', sans-serif;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow: visible !important;;
}

p {
    margin: auto;
}

.configurator {
    cursor: pointer;
    color: orange;
    max-width: max-content;
}
.input-count {
    width: 80px;
}
.descriptionIcon {
    color: #797979;
}

.flex {
    display: flex;
    align-items: center;
    margin-right: 10px;

}

.body-container {
    padding-bottom: 70px;
    outline: none;
}

.nomenclature {
    position: fixed;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #ffffff;
    width: -webkit-fill-available;
    width: -moz-available;
    font-size: 10px;
    z-index: 10
}

.invalid {
    background: #ffecec;
}

.valid {
    background: #ecffe4;
}

.logo {
    display: inline-block;
    position: relative;
    max-width: 100px;
    line-height: 50px;
    z-index: 10;
}

.group_card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    background-color: #f8f9fa; /* Цвет фона карточки */
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    text-align: center;
    padding-bottom: 0;
}

.group_card_selected {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    background-color: #c3fdbd85; /* Цвет фона карточки */
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    text-align: center;
    padding-bottom: 0;
}


.group-title {
    margin-bottom: 5px;
    margin-top: 5px;
}

.component-card {
    margin-top: 5px;
}

.component-list {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.component-list li {
    cursor: pointer;
    padding: 5px;
    margin: 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #dadada;
}

.component-list li.selected {
    background-color: #51e774;
    cursor: pointer;
}

.component-list li.block {
    cursor: no-drop;
    background-color: #b3b0b080;
    pointer-events: none;
}

.component-list li.active:hover {
    background-color: orange;
}

.component-list li.lastElement {
    background-color: #8deea3d0;
    cursor: pointer;
}

ion-icon {
    font-size: x-large !important;
    padding-right: 10px;
    padding-left: 10px;
    pointer-events: none;
}

.controller {
    cursor: pointer;
    margin-left: 15px;
    
}

.currentNomenclature h1 {
    padding: 10px;
    margin: 0;
}

.currentNomenclature {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart.hidden {
    display: none;
}

.cart {
    display: block;
    max-height: 840px;
    overflow: auto;
}

.cart-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
}

.cart-item-button {
    margin-top: 0.65%
}

.cart-item-name {
    font-size: medium;
    padding-right: 50px;
}

.markdown-container {
    width: 80%; /* Ширина контейнера */
    margin: 20px auto; /* Центрирование по горизонтали с отступом сверху 20px */
    padding: 20px; /* Внутренний отступ */
    background-color: #f5f5f5; /* Цвет фона */
    border: 1px solid #ddd; /* Рамка */
    border-radius: 5px; /* Закругление углов */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Тень */
  }

  .markdown-container img {
    display: block;
    margin: 10px;
  }

  .markdown-container h2 {
    text-align: center; /* Центрирование заголовков */
}

  .markdown-container h1,
  .markdown-container h2,
  .markdown-container h3 {
    color: #333; /* Цвет заголовков */
  }
  
  .markdown-container p {
    line-height: 1.6; /* Межстрочный интервал для абзацев */
    text-align: justify; /* Выравнивание текста по ширине контейнера */
  }
  
  .markdown-container a {
    color: #007bff; /* Цвет ссылок */
  }
  
  